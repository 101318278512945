import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';


class ActivitiesPageListItem extends React.Component {

    render() {
        const {activity, id} = this.props;
        let startDate = new Intl.DateTimeFormat('nl-NL', {
            'year': 'numeric',
            'month': '2-digit',
            'day': '2-digit'
        }).format(new Date(activity.startDate));
        const trClass = activity.stats.none > 0 ? "table-danger" : "table-success";
        return (
            <React.Fragment>
                <tr class={trClass}>
                    <td>{startDate}</td>
                    <td>{activity.name}</td>
                    <td>{activity.stats.none}</td>
                    <td>{activity.stats.present}</td>
                    <td>{activity.stats.absent}</td>
                    <td>{activity.stats.sick}</td>
                    <td>{activity.stats.missing}</td>
                    <td>{activity.stats.no_class}</td>
                    <td>{activity.stats.non_active}</td>
                    <td>
                        <Link className='btn cur-p btn-primary' type="button" to={"/activities/" + id}>
                            Vul aanwezigheid in
                        </Link>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownState) {
    const {activity, id} = ownState;
    return {
        activity,
        id
    };
}

const connectedActivitiesPageListItem = connect(mapStateToProps)(ActivitiesPageListItem);
export {connectedActivitiesPageListItem as ActivitiesPageListItem};
