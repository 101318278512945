import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {userActions} from '../_actions';

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(userActions.logout());
    }

    render() {
        return <Redirect to={"/"}/>
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedLogoutPage = connect(mapStateToProps)(LogoutPage);
export {connectedLogoutPage as LogoutPage};