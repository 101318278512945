import {activityConstants} from '../_constants';

const initialState = {activityList: [], participatorList: [], loading: false};

export function activities(state = initialState, action) {

    switch (action.type) {
        case activityConstants.GET_OWN_ACTIVITIES_REQUEST:
            return {...state, loading: true}
        case activityConstants.GET_OWN_ACTIVITIES_SUCCESS:
            return {...state, activityList: action.activities, loading: false}
        case activityConstants.GET_OWN_ACTIVITIES_FAILURE:
            return {...state, error: action.error, loading: false}

        case activityConstants.GET_PARTICIPATOR_LIST_REQUEST:
            return {...state, loading: true}
        case activityConstants.GET_PARTICIPATOR_LIST_SUCCESS:
            return {...state, participatorList: action.participatorList, loading: false}
        case activityConstants.GET_PARTICIPATOR_LIST_FAILURE:
            return {...state, error: action.error, loading: false}
        case activityConstants.SET_PARTICIPATOR_REMARK_SUCCESS:
            const tempListItem = action.participatorList
            let currentList = [...state.participatorList];
            let index = currentList.findIndex(obj => obj.id === tempListItem['id']);
            currentList[index].remark = tempListItem['remark'];
            return {...state, participatorList: currentList, loading: false}
        case activityConstants.SET_PARTICIPATOR_REMARK_FAILURE:
            return {...state, error: action.error, loading: false}
        default:
            return {...state}
    }
}