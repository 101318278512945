import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class TopBar extends React.Component {

    handleToggle() {
        if (document.body.classList.contains('is-collapsed')) {
            document.body.classList.remove('is-collapsed');
        } else {
            document.body.classList.add('is-collapsed');
        }
    }

    render() {
        return (
            <div className="header navbar">
                <div className="header-container">
                    <ul className="nav-left">
                        <li>
                            <a id='sidebar-toggle' className="sidebar-toggle" onClick={this.handleToggle.bind(this)}>
                                <i className="ti-menu"/>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav-right">
                        <li className="dropdown">
                            <a href="" className="dropdown-toggle no-after peers fxw-nw ai-c lh-1"
                               data-toggle="dropdown">
                                <div className="peer mR-10">
                                    <img className="w-2r bdrs-50p"
                                         src="https://randomuser.me/api/portraits/men/10.jpg" alt=""/>
                                </div>
                                <div className="peer">
                                    <span className="fsz-sm c-grey-900">{this.props.userInfo.fullName}</span>
                                </div>
                            </a>
                            <ul className="dropdown-menu fsz-sm">
                                <li>
                                    <a href="" className="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                        <i className="ti-settings mR-10"/>
                                        <span>Setting</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                        <i className="ti-user mR-10"/>
                                        <span>Profile</span>
                                    </a>
                                </li>
                                <li role="separator" className="divider"/>
                                <li>
                                    <a href="" className="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                        <i className="ti-power-off mR-10"/>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changePage: () => push('/about-us')
}, dispatch)

export default connect(
    null,
    mapDispatchToProps
)(TopBar)