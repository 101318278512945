import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import SideBar from "./../Layout/SideBar";
import TopBar from "./../Layout/TopBar";


class HomePage extends React.Component {
    componentDidMount() {
        if (document.body.classList.contains('is-collapsed')) {
            document.body.classList.remove('is-collapsed');
        }
    }

    render() {
        return <Redirect to='/activities'/>;
        // const { userInfo } = this.props;
        // console.log(userInfo);
        // return (
        //    <React.Fragment>
        //        <SideBar />
        //        <div className="page-container">
        //            <TopBar userInfo={userInfo} />
        //            <main className='main-content bgc-grey-100'>
        //                <div id='mainContent'>
        //                    <p>Hoi, hier kan alles staan wat jullie willen!</p>
        //                </div>
        //            </main>
        //            <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
        //             <span>Ster van Maastricht
        //             </span>
        //            </footer>
        //        </div>
        //    </React.Fragment>
        // );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { userInfo } = authentication;
    return {
        userInfo
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };