import { authHeader } from '../_helpers';
import { userService } from './user.service'

export const activityService = {
    getOwnActivities,
    getParticipatorList,
    setParticipatorStatus,
    setParticipatorRemark,
};

function getOwnActivities() {
    let headers = authHeader();
    headers = {...headers, 'Content-Type': 'application/json'}
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/activities`, requestOptions).then(handleResponse);
}

function getParticipatorList(activityId) {
    let headers = authHeader();
    headers = {...headers, 'Content-Type': 'application/json'}
    const requestOptions = {
      method: 'GET',
      headers: headers
    };

    return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/activity_participators?id=` + activityId, requestOptions).then(handleResponse);
}

function setParticipatorStatus(participatorId, status) {
    let headers = authHeader();
    headers = {...headers, 'Content-Type': 'application/json'}
    const requestOptions = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({'status': status}),
    };

    return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/participators/` + participatorId, requestOptions).then(handleResponse);
}

function setParticipatorRemark(participatorId, remark) {
    let headers = authHeader();
    headers = {...headers, 'Content-Type': 'application/json'}
    const requestOptions = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({'remark': remark}),
    };

    return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/participators/` + participatorId + ".json", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
