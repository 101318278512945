import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import {Button, Modal, Form} from 'react-bootstrap';
import {activityAction} from "../_actions";

class ActivitiesPageDetailListItem extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);


        this.state = {
            show: false,
            remarkRef: React.createRef(),
        }
    }

    handleOpen() {
        this.setState({
            show: true,
        })
    }

    handleClose() {
        this.setState({
            show: false,
        })
    }

    handleActivityStatus(value, selectedObject) {
        const newStatus = value;
        const id = this.props.id;
        this.props.dispatch(activityAction.setParticipatorStatus(id, newStatus));
    }

    handleRemarkSave() {
        const {remarkRef} = this.state;
        const value = remarkRef.current.value;
        const id = this.props.id;
        this.props.dispatch(activityAction.setParticipatorRemark(id, value));
        this.setState({
            show: false,
        })
    }

    render() {
        const {id, participatorList} = this.props;
        const participator = participatorList[id];
        const { remarkRef } = this.state;
        let startDate = new Intl.DateTimeFormat('nl-NL', {
            'hour': '2-digit',
            'minute': '2-digit'
        }).format(new Date(participator.startDate));
        let endDate = new Intl.DateTimeFormat('nl-NL', {
            'hour': '2-digit',
            'minute': '2-digit'
        }).format(new Date(participator.endDate));

        let remark = (<Button onClick={this.handleOpen}>Voeg opmerking toe</Button>);
        if (participator.remark !== null && participator.remark !== "") {
            remark =  <React.Fragment>
                <Button variant="outline-secondary" size="sm" onClick={this.handleOpen}>
                <i className="fa fa-pencil-square-o"/>
                </Button>
                <strong>Opmerking</strong> {participator.remark}
            </React.Fragment>
        }
        let modalPart = (
            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Voeg opmerking toe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control as="textarea" rows="3" defaultValue={participator.remark} ref={remarkRef}  />
                    <Button variant="primary" type="submit" onClick={this.handleRemarkSave.bind(this)}>
                        Opslaan
                    </Button>
                </Modal.Body>
            </Modal>
        )


        return (
            <React.Fragment>
                <tr>
                    <td>{participator.name}</td>
                    <td>{startDate} - {endDate}</td>
                    <td>
                        <ButtonToolbar>
                            <ToggleButtonGroup type="radio" name="options"
                                               defaultValue={participator.status}
                                               onChange={this.handleActivityStatus.bind(this)}>
                                <ToggleButton variant="outline-success"
                                              value={0}>Aanwezig</ToggleButton>
                                <ToggleButton variant="outline-secondary"
                                              value={1}>Afgemeld</ToggleButton>
                                <ToggleButton variant="outline-secondary"
                                              value={2}>Ziek</ToggleButton>
                                <ToggleButton variant="outline-danger" value={3}>Niet
                                    aanwezig</ToggleButton>
                                <ToggleButton variant="outline-danger" value={4}>Geen les</ToggleButton>
                                <ToggleButton variant="outline-danger" value={5}>Non-actief</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                        {remark}
                        {modalPart}
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownState) {
    const {activities} = state;
    const {participatorList} = activities;
    const {id} = ownState;
    return {
        participatorList,
        id
    };
}

const connectedActivitiesPageDetailListItem = connect(mapStateToProps)(ActivitiesPageDetailListItem);
export {connectedActivitiesPageDetailListItem as ActivitiesPageDetailListItem};
