import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {activityAction} from '../_actions';
import SideBar from "./../Layout/SideBar";
import TopBar from "../Layout/TopBar";
import {ActivitiesPageListItem} from "./ActivitiesPageListItem";


class ActivitiesPage extends React.Component {
    componentDidMount() {
        this.props.dispatch(activityAction.getOwnActivities());
        if (document.body.classList.contains('is-collapsed')) {
            document.body.classList.remove('is-collapsed');
        }
    }

    render() {
        const {userInfo, activityList, loading} = this.props;


        if (loading) {
            return (
                <React.Fragment>
                    <SideBar/>
                    <div className="page-container">
                        <TopBar userInfo={userInfo}/>
                        <main className='main-content bgc-grey-100'>
                            <div id='mainContent'>
                                <div className="loader fadeOut">
                                    <div className="spinner"/>
                                </div>
                            </div>
                        </main>
                        <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
                    <span>Ster van Maastricht
                    </span>
                        </footer>
                    </div>
                </React.Fragment> )
        }
        const list = Object.keys(activityList).map(key =>
               <ActivitiesPageListItem activity={activityList[key]} id={key} key={key}/>
        )

        return (
            <React.Fragment>
                <SideBar/>
                <div className="page-container">
                    <TopBar userInfo={userInfo}/>
                    <main className='main-content bgc-grey-100'>
                        <div id='mainContent'>
                            <div className="container-fluid">
                                <h4 className="c-grey-900 mT-10 mB-30">Activiteiten</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="bgc-white bd bdrs-3 p-20 mB-20">
                                            <table id="dataTable" className="table table-striped table-bordered"
                                                   cellSpacing="0" width="100%">
                                                <thead>
                                                <tr>
                                                    <th>Datum</th>
                                                    <th>Groep</th>
                                                    <th>Niet ingevuld</th>
                                                    <th>Aanwezig</th>
                                                    <th>Afgemeld</th>
                                                    <th>Ziek</th>
                                                    <th>Niet aanwezig</th>
                                                    <th>Geen les</th>
                                                    <th>Non-actief</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tfoot>
                                                <tr>
                                                    <th>Datum</th>
                                                    <th>Groep</th>
                                                    <th>Niet ingevuld</th>
                                                    <th>Aanwezig</th>
                                                    <th>Afgemeld</th>
                                                    <th>Ziek</th>
                                                    <th>Niet aanwezig</th>
                                                    <th>Geen les</th>
                                                    <th>Non-actief</th>
                                                    <th></th>
                                                </tr>
                                                </tfoot>
                                                <tbody>
                                                    {list}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
                    <span>Ster van Maastricht
                    </span>
                    </footer>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {authentication, activities} = state;
    const {userInfo} = authentication;
    return {
        userInfo,
        activityList: activities.activityList,
        loading: activities.loading
    };
}

const connectedActivitiesPage = connect(mapStateToProps)(ActivitiesPage);
export {connectedActivitiesPage as ActivitiesPage};