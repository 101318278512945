import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import createRootReducer from '../_reducers'
import logger from 'redux-logger'
export const history = createBrowserHistory()

//
// export const store = createStore(
//     rootReducer,
//     applyMiddleware(
//         thunkMiddleware,
//         loggerMiddleware
//     )
// );
//
//
//
// const localStorageUser = localStorage.getItem('user');
// let user = null;
// let userInfo = {};
// if (localStorageUser != null) {
//     user = jwtdecode(localStorage.getItem('user'));
//     userInfo = user ? user : {};
// }


const initialState = {}
const enhancers = []
const middleware = [
    thunk,
    routerMiddleware(history),
    logger
]

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

export default createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
)