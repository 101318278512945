import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {activityAction, userActions} from '../_actions';
import SideBar from "./../Layout/SideBar";
import TopBar from "../Layout/TopBar";
import {ActivitiesPageDetailListItem} from "./ActivitiesPageDetailListItem";

class ActivitiesPageDetail extends React.Component {

    componentDidMount() {
        this.props.dispatch(activityAction.getParticipatorList(this.props.match.params.id));
        if (this.props.activityList.length === 0) {
            this.props.dispatch(activityAction.getOwnActivities());
        }
        if (document.body.classList.contains('is-collapsed')) {
            document.body.classList.remove('is-collapsed');
        }
    }

    render() {
        const {userInfo, loading, participatorList, activityList} = this.props;

        if (loading) {
            return (
                <React.Fragment>
                    <SideBar/>
                    <div className="page-container">
                        <TopBar userInfo={userInfo}/>
                        <main className='main-content bgc-grey-100'>
                            <div id='mainContent'>
                                <div className="loader fadeOut">
                                    <div className="spinner"/>
                                </div>
                            </div>
                        </main>
                        <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
                    <span>Ster van Maastricht
                    </span>
                        </footer>
                    </div>
                </React.Fragment> )
        }

        const list = Object.keys(participatorList).map(key =>
            <ActivitiesPageDetailListItem id={key} key={participatorList[key].id}/>
        )

        const activity = activityList[this.props.match.params.id];
        let startDate = '';
        let endDate = '';
        let activityName = '';
        if (undefined !== activity) {
            activityName = activity.name;
            startDate = new Intl.DateTimeFormat('nl-NL', {
                'year': 'numeric',
                'month': '2-digit',
                'day': '2-digit'
            }).format(new Date(activity.startDate));
            endDate = new Intl.DateTimeFormat('nl-NL', {
                'year': 'numeric',
                'month': '2-digit',
                'day': '2-digit'
            }).format(new Date(activity.endDate));
        }
        let stringDate = '';
        if (startDate === endDate) {
            stringDate = startDate;
        } else {
            stringDate = startDate + ' t/m ' + endDate;
        }
        return (
            <React.Fragment>
                <SideBar/>
                <div className="page-container">
                    <TopBar userInfo={userInfo}/>
                    <main className='main-content bgc-grey-100'>
                        <div id='mainContent'>
                            <div className="container-fluid">
                                <h4 className="c-grey-900 mT-10 mB-30">Activiteiten - Details</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="bgc-white bd bdrs-3 p-20 mB-20">
                                            <h4>{stringDate} - {activityName}</h4>
                                            <table id="dataTable" className="table table-striped table-bordered"
                                                   cellSpacing="0" width="100%">
                                                <thead>
                                                <tr>
                                                    <th>Leerling</th>
                                                    <th>Tijdstip</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tfoot>
                                                <tr>
                                                    <th>Leerling</th>
                                                    <th>Tijdstip</th>
                                                    <th>Status</th>
                                                </tr>
                                                </tfoot>
                                                <tbody>
                                                {list}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
                    <span>Ster van Maastricht
                    </span>
                    </footer>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {authentication, activities} = state;
    const {userInfo} = authentication;
    const {participatorList, loading, activityList} = activities;
    return {
        userInfo,
        participatorList,
        loading,
        activityList
    };
}

const connectedActivitiesPageDetail = connect(mapStateToProps)(ActivitiesPageDetail);
export {connectedActivitiesPageDetail as ActivitiesPageDetail};
