import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as reducerForm } from 'redux-form';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { activities } from './activities.reducer';
import { alert } from './alert.reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  form: reducerForm,
  authentication,
  users,
  alert,
  activities
})