import React from 'react';
import {Router, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import {history} from '../_helpers';
import {alertActions} from '../_actions';
import {PrivateRoute} from '../_components';
import {HomePage} from '../HomePage';
import {LoginPage, LogoutPage} from '../LoginPage';
import {ActivitiesPage} from "../ActivitiesPage/ActivitiesPage";
import {ActivitiesPageDetail} from "../ActivitiesPage/ActivitiesPageDetail";

class App extends React.Component {
    constructor(props) {
        super(props);

        const {dispatch} = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }

    render() {
        const {alert} = this.props;
        return (
            <React.Fragment>
                {alert.message &&
                <div className={`alert ${alert.type}`}>{alert.message}</div>
                }
                <Router history={history}>
                    <div>
                        <PrivateRoute exact path="/" component={HomePage}/>
                        <PrivateRoute exact path="/activities" component={ActivitiesPage}/>
                        <PrivateRoute exact path="/activities/:id" component={ActivitiesPageDetail}/>
                        <Route path="/login" component={LoginPage}/>
                        <Route path="/logout" component={LogoutPage}/>
                    </div>
                </Router>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
