import { activityConstants } from '../_constants';
import { activityService } from '../_services';

export const activityAction = {
    getOwnActivities,
    getParticipatorList,
    setParticipatorStatus,
    setParticipatorRemark,
};

function getOwnActivities() {
    return dispatch => {
        dispatch(request());

        activityService.getOwnActivities()
            .then(
                activities => dispatch(success(activities)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: activityConstants.GET_OWN_ACTIVITIES_REQUEST } }
    function success(activities) { return { type: activityConstants.GET_OWN_ACTIVITIES_SUCCESS, activities: activities } }
    function failure(error) { return { type: activityConstants.GET_OWN_ACTIVITIES_FAILURE, error } }
}

function getParticipatorList(activityId) {
    return dispatch => {
        dispatch(request());

        activityService.getParticipatorList(activityId)
            .then(
                participatorList => dispatch(success(participatorList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: activityConstants.GET_PARTICIPATOR_LIST_REQUEST } }
    function success(participatorList) { return { type: activityConstants.GET_PARTICIPATOR_LIST_SUCCESS, participatorList: participatorList } }
    function failure(error) { return { type: activityConstants.GET_PARTICIPATOR_LIST_FAILURE, error } }
}

function setParticipatorStatus(participatorId, status) {
    return dispatch => {
        dispatch(request());

        activityService.setParticipatorStatus(participatorId, status)
            .then(
                participatorList => dispatch(success(participatorList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: activityConstants.SET_PARTICIPATOR_STATUS_REQUEST } }
    function success(participatorList) { return { type: activityConstants.SET_PARTICIPATOR_STATUS_SUCCESS, participatorList: participatorList } }
    function failure(error) { return { type: activityConstants.SET_PARTICIPATOR_STATUS_FAILURE, error } }
}

function setParticipatorRemark(participatorId, remark) {
    return dispatch => {
        dispatch(request());

        activityService.setParticipatorRemark(participatorId, remark)
            .then(
                participatorList => dispatch(success(participatorList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: activityConstants.SET_PARTICIPATOR_REMARK_REQUEST } }
    function success(participatorList) { return { type: activityConstants.SET_PARTICIPATOR_REMARK_SUCCESS, participatorList: participatorList } }
    function failure(error) { return { type: activityConstants.SET_PARTICIPATOR_REMARK_FAILURE, error } }
}