import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'


class SideBar extends React.Component {

    handleToggle() {
        if (document.body.classList.contains('is-collapsed')) {
            document.body.classList.remove('is-collapsed');
        } else {
            document.body.classList.add('is-collapsed');
        }
    }

    render() {
        return (
            <div className="sidebar">
                <div className="sidebar-inner">
                    <div className="sidebar-logo">
                        <div className="peers ai-c fxw-nw">
                            <div className="peer peer-greed">
                                <Link to="/" className="sidebar-link td-n">
                                    <div className="peers ai-c fxw-nw">
                                        <div className="peer">
                                            <div className="logo">
                                                <img src={require("./../assets/static/images/logo.png")} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="peer" onClick={this.handleToggle.bind(this)}>
                                <div className="mobile-toggle sidebar-toggle">
                                    <i className="ti-arrow-circle-left"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="sidebar-menu scrollable pos-r">
                        {/*<li className="nav-item mT-30 active">*/}
                        {/*    <Link to="/" className="sidebar-link">*/}
                        {/*        <span className="icon-holder"><i className="c-blue-500 ti-home"/></span>*/}
                        {/*        <span className="title">Dashboard</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <Link className='sidebar-link' to="/activities">
                                <span className="icon-holder"><i className="c-deep-orange-500 ti-calendar"/></span>
                                <span className="title">Activities</span>
                            </Link>
                            <Link className='sidebar-link' to="/logout">
                                <span className="icon-holder"><i className="c-deep-orange-500 ti-power-off"/></span>
                                <span className="title">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changePage: () => push('/about-us')
}, dispatch)

export default connect(
    null,
    mapDispatchToProps
)(SideBar)
