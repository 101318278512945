export const activityConstants = {
    GET_OWN_ACTIVITIES_REQUEST: 'GET_OWN_ACTIVITIES_REQUEST',
    GET_OWN_ACTIVITIES_SUCCESS: 'GET_OWN_ACTIVITIES_SUCCESS',
    GET_OWN_ACTIVITIES_FAILURE: 'GET_OWN_ACTIVITIES_FAILURE',
    GET_PARTICIPATOR_LIST_REQUEST: 'GET_PARTICIPATOR_LIST_REQUEST',
    GET_PARTICIPATOR_LIST_SUCCESS: 'GET_PARTICIPATOR_LIST_SUCCESS',
    GET_PARTICIPATOR_LIST_FAILURE: 'GET_PARTICIPATOR_LIST_FAILURE',
    SET_PARTICIPATOR_STATUS_REQUEST: 'SET_PARTICIPATOR_STATUS_REQUEST',
    SET_PARTICIPATOR_STATUS_SUCCESS: 'SET_PARTICIPATOR_STATUS_SUCCESS',
    SET_PARTICIPATOR_STATUS_FAILURE: 'SET_PARTICIPATOR_STATUS_FAILURE',
    SET_PARTICIPATOR_REMARK_REQUEST: 'SET_PARTICIPATOR_REMARK_REQUEST',
    SET_PARTICIPATOR_REMARK_SUCCESS: 'SET_PARTICIPATOR_REMARK_SUCCESS',
    SET_PARTICIPATOR_REMARK_FAILURE: 'SET_PARTICIPATOR_REMARK_FAILURE',
};
